import React, { useState, useEffect } from 'react';
import Card from "../../Components/Card_Link_Vendas/card";
import './index.css';

function Shop() {
  const [searchTerm, setSearchTerm] = useState('');
  const [segmento, setSegmento] = useState('');
  const [produtos, setProdutos] = useState([]); // Estado inicial é um array vazio
  const [filteredProdutos, setFilteredProdutos] = useState([]);
  const [error, setError] = useState(''); // Estado para armazenar mensagens de erro

  // Função para buscar os dados do banco (API)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://apishop-onlynebrasil.com.br');

        if (!response.ok) {
          throw new Error(`Erro ao buscar produtos: ${response.statusText}`);
        }

        const data = await response.json();
        console.log(data); // Veja a estrutura dos dados aqui
        
        // Verifica se 'produtos' está disponível e é um array, se não cria um array
        const produtos = Array.isArray(data) ? data : [data]; // Garante que produtos seja um array
        
        setProdutos(produtos);
        setFilteredProdutos(produtos); // Exibe todos os produtos inicialmente
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setError('Erro ao buscar dados da API.'); // Define mensagem de erro
      }
    };

    fetchData();
  }, []);

  // Atualiza a busca conforme o usuário digita
  useEffect(() => {
    const resultadosFiltrados = produtos.filter(produto =>
      produto.texto.toLowerCase().includes(searchTerm.toLowerCase()) // Filtra pelo texto
    );
    setFilteredProdutos(resultadosFiltrados);
  }, [searchTerm, produtos]);

  // Função para lidar com a mudança do termo de busca
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Função para lidar com o filtro por segmento (dropdown)
  const handleSegmentoChange = (e) => {
    setSegmento(e.target.value);
    if (e.target.value === '') {
      setFilteredProdutos(produtos); // Se nenhum segmento for escolhido, exibe todos os produtos
    } else {
      const resultadosFiltrados = produtos.filter(produto =>
        produto.segmento === e.target.value // Filtra pelo segmento
      );
      setFilteredProdutos(resultadosFiltrados);
    }
  };

  // Função para lidar com o filtro por plataforma (botões)
  const handlePlatformFilter = (plataforma) => {
    const resultadosFiltrados = produtos.filter(produto =>
      produto.link.includes(plataforma) // Filtra pelo link da plataforma
    );
    setFilteredProdutos(resultadosFiltrados);
  };

  return (
    <div className="app-container">
      {/* Mensagem de erro se houver */}
      {error && <p className="error-message">{error}</p>}

      {/* Barra de Pesquisa */}
      <section className="search-section">
        <input 
          type="text" 
          placeholder="Buscar produtos, lojas..." 
          value={searchTerm} 
          onChange={handleSearch} 
          className="search-input"
        />
      </section>

      {/* Perfil da Loja */}
      <section className="store-profile">
        <div className="store-info">
          <img src={`${process.env.PUBLIC_URL}/favicon.webp`} alt="Perfil da Loja" className="store-logo"/>
          <div className="store-details">
            <h2>Onlyne Brasil</h2>
            <p>Aqui você encontra os melhores produtos de diversas categorias...</p>
          </div>
        </div>
      </section>

      {/* Dropdown de Segmentos */}
      <div className="dropdown-segmento">
        <select id="segmento" value={segmento} onChange={handleSegmentoChange}>
          <option value="">Selecione um segmento</option>
          <option value="decoracao">Decoração</option>
          <option value="limpeza">Limpeza</option>
          <option value="eletronicos">Eletrônicos</option>
          <option value="cozinha">Cozinha</option>
          <option value="enxoval">Enxoval</option>
        </select>
      </div>

      {/* Botões de Refinamento */}
      <div className="refinement-buttons">
        <button className="btn-shopee" onClick={() => handlePlatformFilter('shopee')}>Shopee</button>
        <button className="btn-amazon" onClick={() => handlePlatformFilter('amazon')}>Amazon</button>
        <button className="btn-aliexpress" onClick={() => handlePlatformFilter('aliexpress')}>AliExpress</button>
      </div>

      {/* Exibição dos Produtos */}
      <section className="produtos">
        {filteredProdutos.length > 0 ? (
          filteredProdutos.map((produto) => (
            <Card
              key={produto.id} // Certifique-se de que os produtos tenham um ID único
              link={produto.link}
              imagem={produto.imagem}
              texto={produto.texto}
            />
          ))
        ) : (
          <p>Nenhum produto encontrado.</p>
        )}
      </section>
    </div>
  );
}

export default Shop;
