


export const author1 = 'Onlyne Brasil';





export const title1 = '';









export const topic1 = 'Area em desenvolvimento';









export const des1 = 'Nosso site ainda está em fase de desenvolvimento, pode ser utilizada a área de shop para pesquisar os links de produtos que você precisa. Pedimos desculpas pelo transtorno.';