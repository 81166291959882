import React, { useState } from 'react';
import './Index.css';
import { NavLink } from 'react-router-dom';
import { FaInstagram, FaFacebook} from 'react-icons/fa';
import Logo from '../../assets/favicon.webp';

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

   
    return (
        <header className={isOpen ? 'expanded' : ''}>
            <nav id='navbar' className="navbar">
                <img 
                    className="nav_logo" 
                    src={Logo} 
                    alt="Logo da empresa Odontoclinic em azul com um coração feito de círculos"
                />
                <button id='mobile_btn' onClick={toggleMenu} aria-label="Toggle menu">
                    &#9776;
                </button>
                <ul id='nav_list' className={`nav_list ${isOpen ? 'active' : ''}`}>
                    <li className="nav-item">
                        <NavLink exact to="/" activeClassName="active" onClick={toggleMenu}>Home</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/" activeClassName="active" onClick={toggleMenu}>Casa</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/" activeClassName="active" onClick={toggleMenu}>Moda</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/" activeClassName="active" onClick={toggleMenu}>Lazer</NavLink>
                    </li>
                </ul>
                <button id="btn-nav" className="btn-default" onClick={() => window.location.href="https://onlynebrasil.com/shop"}>Shop</button>

                <div className="social-media-buttons">
                    <a href="https://www.facebook.com/onlynebrasil" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
                    <a href="https://www.instagram.com/onlynebrasil/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                </div>
            </nav>
        </header>
    );
}
