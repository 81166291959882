import React from 'react';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Shop from "./Pages/Shop/Shoplink"
import Home from './Pages/Home/Home';
import './App.css'



export default function App() {
  return (

    <Router>
      <Routes>
        
      <Route path="/" element={<Home/>} />
      <Route path="/shop" element={<Shop  />} />

      </Routes>
    </Router>
    
  );
}

