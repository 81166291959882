import Navbar from '../../Components/Navbar/Navbar'
import Slider from '../../Components/Slider/Slider'
export default function Home(){
    return(
        <>
      <Navbar/>
      <Slider/>
        </>
    )

};