import React from 'react';
import './index.css'; // Estilos específicos para o card

const Card = ({ link, imagem, texto }) => {
  return (
    <div className="card">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <img src={imagem} alt={texto} />
        <div className="card-title">{texto}</div>
      </a>
    </div>
  );
};

export default Card;
