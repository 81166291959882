import React, { useState, useEffect, useRef } from 'react';
// Imports de Desc Inicio 
import { author1, title1, topic1, des1 } from './ConteudoSlider1';
import './Index.css';
import img1 from '../../assets/icon.png';


// Estrutura de dados para incluir imagens locais
let data = [
  {
    imgSrc: img1,
    author: author1,
    title: title1,
    topic: topic1,
    des: des1,
  },
  
];

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const autoSlideTimeout = useRef(null);
  const animationTimeout = useRef(null);

  useEffect(() => {
    autoSlideTimeout.current = setTimeout(handleNext, 7000);
    return () => clearTimeout(autoSlideTimeout.current);
  }, [currentIndex]);

  const handleNext = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    resetAnimation();
  };

  const handlePrev = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
    resetAnimation();
  };

  const resetAnimation = () => {
    clearTimeout(animationTimeout.current);
    animationTimeout.current = setTimeout(() => {
      setIsAnimating(false);
    }, 3000);
  };

  const handleThumbnailClick = (index) => {
    if (isAnimating) return;
    setCurrentIndex(index);
    resetAnimation();
  };

  return (
    <div className="carousel">
      <div className="list">
        {data.map((item, index) => (
          <div
            key={index}
            className={`item ${index === currentIndex ? 'active' : ''}`}
            style={{ display: index === currentIndex ? 'block' : 'none' }}
          >
            <img 
              src={item.imgSrc} 
              alt={item.title} 
              style={{ width: '100%', borderRadius: '8px', boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)', marginBottom: '0.9em' }} 
            />
            <div className="content">
              <div className="author">{item.author}</div>
              <div className="title">{item.title}</div>
              <div className="topic">{item.topic}</div>
              <div className="des">{item.des}</div>
            </div>
          </div>
        ))}
      </div>
     
      <div className="arrows">
        <button id="prev" onClick={handlePrev}>{'<'}</button>
        <button id="next" onClick={handleNext}>{'>'}</button>
      </div>
      <div className="time"></div>
    </div>
  );
};

export default Slider;
